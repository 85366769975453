// const apiTool = require('./apiTool')
import { Message } from 'ant-design-vue'
import apiTool from './apiTool'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { isShiban } from '@/utils/env'
export const getParams = function getParams(params = {}) {
  let text = ''
  Object.keys(JSON.parse(JSON.stringify(params))).forEach((e) => {
    if (params[e] !== undefined && params[e] !== 'null') {
      text += e + '=' + params[e] + '&'
    }
  })
  if (text) {
    text = '?' + text
  }
  return text
}

export const getHeaders = function (params = {}) {
  const token = (localStorage.getItem(ACCESS_TOKEN)||'').replace(/"/g, '')
  const type = Object.prototype.toString.call(params)
  const headers = {
    'Content-Type': 'application/json',
    'X-Application-name': 'app',
    Authorization: `bearer ${token}`,
  }

  if(isShiban){
    headers['platform'] = '3302'
  }

  if (type == '[object FormData]') {
    delete headers['Content-Type']
  }
  return headers
}

export const postAction = function (url, params = {}) {
  const type = Object.prototype.toString.call(params)
  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: type == '[object FormData]' ? params : JSON.stringify(params),
      headers: getHeaders(params),
    })
      .then((e) => {
        return new Promise(async (resolve) => {
          resolve({
            ...(await e.json()),
            status: e.status,
          })
        })
      })
      .then((e) => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const putAction = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include',
      method: 'PUT',
      body: JSON.stringify(params),
      headers: getHeaders(),
    })
      .then((e) => {
        return new Promise(async (resolve) => {
          resolve({
            ...(await e.json()),
            status: e.status,
          })
        })
      })
      .then((e) => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const getAction = function (url, params) {
  return new Promise((resolve, reject) => {
    fetch(url + getParams(params), {
      credentials: 'include',
      method: 'GET',
      headers: getHeaders(),
    })
      .then((e) => {
        return new Promise(async (resolve) => {
          resolve({
            ...(await e.json()),
            status: e.status,
          })
        })
      })
      .then((e) => {
        if (e.status == 401) {
          
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e.data)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

// 删除
export const deleteAction = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include',
      method: 'DELETE',
      body: JSON.stringify(params),
      headers: getHeaders(),
    })
      .then((e) => {
        return new Promise(async (resolve) => {
          resolve({
            ...(await e.json()),
            status: e.status,
          })
        })
      })
      .then((e) => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

// importexcel文件
export const importAction = function (url) {
  return new Promise((resolve, reject) => {
    const file = document.createElement('input')
    file.type = 'file'
    file.onchange = (e) => {
      const fileContent = e.target.files[0]
      const formData = new FormData()
      formData.append('file', fileContent, '库主导入模板 (1).xlsx')
      const token = localStorage.getItem('CX-Access-Token').replace(/"/g, '')
      // 导入excel到服务器
      fetch(url, {
        method: 'POST',
        body: formData,
        redirect: 'follow',
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
        .then((e) => e.json())
        .then((res) => {
          if (res.code == 200) {
            resolve(res)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    }
    file.click()
  })
}

// 文件下载
export const downloadAction = function (url, fileName) {
  fetch(url)
    .then((e) => e.blob())
    .then((e) => {
      const a = document.createElement('a')
      a.href = URL.createObjectURL(e)
      a.download = fileName
      a.click()
    })
}

// 删除
export const delAction = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'include',
      method: 'DELETE',
      body: JSON.stringify(params),
      headers: getHeaders(),
    })
      .then((e) => {
        return new Promise(async (resolve) => {
          resolve({
            ...(await e.json()),
            status: e.status,
          })
        })
      })
      .then((e) => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export default {
  postAction,
  getAction,
  putAction,
  deleteAction,
  delAction,
  importAction,
  downloadAction,
}
