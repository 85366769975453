import { BasicLayout } from '@/layouts'
import { isShiban } from '../utils/env'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
}

export const basePath = isShiban ? '/nb' : ''


export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: RouteView,
    meta: {
      title: '首页',
    },
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/index'),
        meta: {
          title: '首页',
        },
      },
      {
        path: '/apply',
        name: 'apply',
        component: () => import('@/views/index/apply'),
        meta: {
          title: '介绍页',
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    component: RouteView,
    redirect: '/login',
    hidden: true,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register'),
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult'),
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined,
      },
    ],
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/404'),
  },
]
