/**
 * @description 设置用户数据
 */
export const setUserData = (data) => {
    localStorage.setItem('user_info', JSON.stringify(data))
}

/**
 * @description 获取用户数据
 */
export const getUserData = () => {
    return JSON.parse(localStorage.getItem('user_info'))
}
