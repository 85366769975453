import router from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import notification from 'ant-design-vue/es/notification'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import {setUserData} from '@/utils/state'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { i18nRender } from '@/locales'


import {getLoginBySNCodeAPI} from '@/api/login'
import {getUserInfo} from '@/api/user'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const allowList = ['login', 'register', 'registerResult'] // no redirect allowList
const loginRoutePath = '/login'


const routeExists = (path) => {
  return ['login', 'register', 'registerResult'].some((e) => path.includes(e))
}
router.beforeEach(async (to, from, next) => {

  const {token,code} = to.query
  NProgress.start() // start progress bar

  if (token) {
   
    storage.set(ACCESS_TOKEN, to.query.token)
    const {sysUser={}} = await getUserInfo()
    
    if(sysUser?.userId){
      sysUser.id = sysUser.userId
      setUserData(sysUser)
    }
  }
 
  if(code){
    const data =await getLoginBySNCodeAPI(code).then().catch((e)=>e)
    const {token,user_info={}} = data
    if(user_info?.userId){
      user_info.id = user_info.userId
    }
    
    storage.set(ACCESS_TOKEN, token)
    localStorage.setItem('user_info', JSON.stringify(user_info))

    next({ path: '/index' })
    return
  }
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`)
  /* has token */
  if (storage.get(ACCESS_TOKEN)) {
    if (to.path === loginRoutePath) {
      next()
      NProgress.done()
    } else {
      // check login user.roles is null
      if (store.getters.roles.length === 0) {
        // request login userInfo
        store
          .dispatch('GetInfo')
          .then((res) => {
            const roles = res.result && res.result.role
            // generate dynamic router
            store.dispatch('GenerateRoutes', { roles }).then(() => {
              // 根据roles权限生成可访问的路由表
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRouters)
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              const redirect = decodeURIComponent(from.query.redirect || to.path)
              if (to.path === redirect) {
                // set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true })
              } else {
                // 跳转到目的路由
                next({ path: redirect })
              }
            })
          })
          .catch(() => {
            notification.error({
              message: '错误',
              description: '请求用户信息失败，请重试',
            })
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            store.dispatch('Logout').then(() => {
              next({ path: loginRoutePath })
            })
          })
      } else {
        next()
      }
    }
  } else {
    if (routeExists(to.path)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: loginRoutePath })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
