import { getAction } from "../command/netTool"

/**
 * @description 获取用户信息
 */
export const getUserInfo = () => {
   return getAction('/api/admin/user/info').then((res)=>{
        console.log('获取用户信息',res)
        return res
   })
}